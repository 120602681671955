@use 'tailwind-imports';
@use 'typography';

html {
  overflow-x: hidden;

  @media (width <= 768px) {
    &:has(.header-menu--open) {
      @apply overflow-y-hidden;
    }
  }
}

body {
  @apply overflow-x-clip;

  --header-height: 96;

  @media (width <= 768px) {
    &:has(.header-menu--open) {
      @apply overflow-y-hidden;
    }
  }

  &:has(dialog[open]) {
    @apply overflow-y-hidden;
  }
}

%base-content-boundary {
  @apply mx-auto max-w-screen-xl;
}

.content-boundary {
  @extend %base-content-boundary;
}

.content-boundary--w-padding {
  @extend %base-content-boundary;
  @apply px-6 md:px-14;
}

// CUSTOM ATOM CLASSES
.animation-delay-1000 {
  animation-delay: 1s;
}

@layer base {
  @font-face {
    font-family: 'Inter Fallback';
    src: local(arial);
    size-adjust: 117%;
    ascent-override: 90%;
    descent-override: 17%;
    line-gap-override: normal;
  }
  @font-face {
    font-family: 'Montserrat Fallback';
    src: local(arial);
    size-adjust: 114%;
    ascent-override: 87%;
    descent-override: 17%;
    line-gap-override: normal;
  }
}
