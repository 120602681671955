h1,
.h1 {
  @apply text-2xl font-light leading-tight md:text-3xl;
}

h2,
.h2 {
  @apply text-xl font-light md:text-2xl;
}

h3,
.h3 {
  @apply text-lg font-medium md:text-xl;
}

h4,
.h4 {
  @apply text-md font-medium md:text-lg;
}

h5,
.h5 {
  @apply text-base font-medium md:text-md;
}

h6,
.h6 {
  @apply text-initial font-medium;
}

p {
  @apply text-base;
}

ul {
  @apply mx-9 list-disc;

  li {
    @apply pl-2 marker:text-base marker:text-primary-aqua;

    &:not(:first-of-type) {
      @apply mt-4;
    }

    p {
      @apply inline;
    }
  }
}

a {
  @apply transition-colors duration-default;

  &:hover {
    @apply text-primary-aqua;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-montserrat tracking-tighter;
}

.letter-spacing-tight * {
  @apply tracking-tight;
}

.letter-spacing--normal * {
  @apply tracking-normal;
}

.letter-spacing--wide * {
  @apply tracking-wide;
}

// Font Size option in text component
.font-size--small * {
  @apply text-sm;
}

.font-size--initial * {
  @apply text-initial;
}

.font-size--medium * {
  @apply text-base;
}

.font-size--large * {
  @apply text-base md:text-lg;
}

.font-size--x-large * {
  @apply text-mxl md:text-xl;
}

.font-size--xx-large * {
  @apply text-xl md:text-2xl;
}

.font-size--xxx-large * {
  @apply text-m3xl md:text-3xl;
}
